import React from 'react'
import useIsDesktop from '../features/MenuDigital/hooks/useIsDesktop'

type DesktopContextProps = { isDesktop: undefined | boolean }
const defaultState: DesktopContextProps = { isDesktop: undefined }

export const DesktopContext = React.createContext<DesktopContextProps>(defaultState)

const DesktopProvider = ({ children }: React.PropsWithChildren) => {
    const { isDesktop } = useIsDesktop()
    return <DesktopContext.Provider value={{ isDesktop: isDesktop }}>{children}</DesktopContext.Provider>
}

export default DesktopProvider
