import RobotoRegular from '../../assets/fonts/Roboto-Regular.woff2'
import RobotoMedium from '../../assets/fonts/Roboto-Medium.woff2'
import RobotoBlack from '../../assets/fonts/Roboto-Black.ttf'
import RobotoThin from '../../assets/fonts/Roboto-Thin.ttf'
import RobotoLight from '../../assets/fonts/Roboto-Light.ttf'
import DINNextLTProCondensed from '../../assets/fonts/DINNextLTPro-Condensed.woff2'
import DINNextLTProMediumCond from '../../assets/fonts/DINNextLTPro-MediumCond.woff2'

export const fontRoboto = 'Roboto'
export const fontDinNext = 'DIN Next LT Pro'

const MuiCssBaseline = {
    styleOverrides: `
        @font-face {
            font-family: '${fontRoboto}';
            src: local('Roboto'), url(${RobotoRegular}) format('woff2');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontRoboto}';
            src: local('Roboto'), url(${RobotoThin}) format('ttf');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontRoboto}';
            src: local('Roboto'), url(${RobotoLight}) format('ttf');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontRoboto}';
            src: local('Roboto'), url(${RobotoMedium}) format('woff2');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontRoboto}';
            src: local('Roboto'), url(${RobotoBlack}) format('ttf');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontDinNext}';
            src: url(${DINNextLTProCondensed}) format('woff2');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        @font-face {
            font-family: '${fontDinNext}';
            src: url(${DINNextLTProMediumCond}) format('woff2');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
      `,
}

export default MuiCssBaseline
