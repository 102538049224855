import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'

const MuiOutlinedInput = {
    root: { borderRadius: '50px' },
    defaultProps: {
        notched: false,
    },
    styleOverrides: <ComponentsOverrides<Theme>['MuiOutlinedInput']>{
        root: ({ theme }) => ({
            borderRadius: '50px',
            border: `2px solid ${theme.palette.common.black}`,
            '& fieldset': {
                borderColor: 'transparent !important',
            },
        }),
    },
}

export default MuiOutlinedInput
