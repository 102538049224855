import axios, { AxiosResponse } from 'axios'

export const ninkasiInstance = axios.create({ baseURL: import.meta.env.VITE_NINKASI_API_URL })
export const ninkasiFetcher = (url: string, params: object = {}) => {
    return ninkasiInstance.get(url, { params }).then((res: AxiosResponse) => res.data)
}

export const webIdInstance = axios.create({
    baseURL: import.meta.env.VITE_WEBID_API_URL,
    withCredentials: true,
    headers: {
        Authorization: import.meta.env.VITE_WEBID_API_TOKEN,
    },
})
export const webIdFetcher = (url: string, params: object = {}) => {
    return webIdInstance.get(url, { params }).then((res: AxiosResponse) => res.data)
}
