import React, { useMemo } from 'react'
import { MenuDigitalVariant } from '../features/MenuDigital/MenuDigital.types'
import { useSearchParams } from 'react-router-dom'

type PrintModeContextProps = { isPrint: undefined | boolean }
const defaultState: PrintModeContextProps = { isPrint: undefined }

export const PrintModeContext = React.createContext<PrintModeContextProps>(defaultState)

const PrintModeProvider = ({ children }: React.PropsWithChildren) => {
    const [searchParams] = useSearchParams()
    const isPrint = useMemo(
        () =>
            [MenuDigitalVariant.Print, MenuDigitalVariant.PrintDev].includes(
                searchParams.get('typeCarte') as MenuDigitalVariant
            ),
        [searchParams]
    )

    return <PrintModeContext.Provider value={{ isPrint: isPrint }}>{children}</PrintModeContext.Provider>
}

export default PrintModeProvider
