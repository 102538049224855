import React from 'react'
import { MenuIcon } from '../../icons'

import FullPageFeedback from './FullPageFeedback'

const SentryError = () => {
    return <FullPageFeedback icon={<MenuIcon width={120} />} surtitle="OOOUPS !" title="Une erreur est survenue" />
}

export default SentryError
