import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { theme } from '../styles/theme'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import LoadingPage from '../components/Loading/LoadingPage'
import MercureProvider from './MercureProvider'
import RestaurantProvider from './RestaurantProvider'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { fontFamilyDinNext } from '../styles/typography'
import SentryError from '../components/Feedback/SentryError'
import { I18nextProvider } from 'react-i18next'
import i18n from '../translations/i18n'
import DesktopProvider from './DesktopProvider'
import LocaleProvider from './LocaleProvider'

if (import.meta.env.DEV) {
    import('../api/mocks')
}

export const APP_MAX_WIDTH_STYLE = {
    maxWidth: '500px',
    margin: '0 auto',
}

export const GLOBAL_MAX_WIDTH_STYLE = {
    body: { ...APP_MAX_WIDTH_STYLE },
    '& .MuiPaper-root': {
        ...APP_MAX_WIDTH_STYLE,
    },
}

const ConfigProviders = ({ children }: React.PropsWithChildren) => {
    return (
        <I18nextProvider i18n={i18n}>
            <Sentry.ErrorBoundary fallback={<SentryError />}>
                <LocaleProvider>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback={<LoadingPage />}>
                            <DesktopProvider>
                                <RestaurantProvider>
                                    <MercureProvider
                                        options={{
                                            hubUrl: import.meta.env.VITE_MERCURE_HUB_URL,
                                            subscriberJwt: import.meta.env.VITE_MERCURE_JWT,
                                        }}
                                    >
                                        <CssBaseline />
                                        <GlobalStyles
                                            styles={(theme) => ({
                                                html: {
                                                    fontSize: '62.5%',
                                                },
                                                a: {
                                                    textDecoration: 'none',
                                                },
                                                '& .Toastify--animate': {
                                                    animationDuration: '0.3s',
                                                },
                                                '& .Toastify__toast': {
                                                    fontSize: '1.8rem',
                                                },
                                                ':root': {
                                                    backgroundColor: '#F6F6F9',
                                                    '--toastify-font-family': fontFamilyDinNext,
                                                    '--toastify-color-light': `${theme.palette.common.white}`,
                                                    '--toastify-color-dark': `${theme.palette.common.black}`,
                                                    '--toastify-color-success': `${theme.palette.success.main}`,
                                                    '--toastify-color-warning': `${theme.palette.warning.main}`,
                                                    '--toastify-color-error': `${theme.palette.error.main}`,
                                                    '--toastify-text-color-light': `${theme.palette.common.black}`,
                                                    '--toastify-text-color-dark': `${theme.palette.common.white}`,
                                                },
                                            })}
                                        />
                                        <BrowserRouter>{children}</BrowserRouter>
                                        <ToastContainer
                                            position={toast.POSITION.TOP_CENTER}
                                            closeOnClick
                                            autoClose={3000}
                                            transition={Slide}
                                            theme="colored"
                                        />
                                    </MercureProvider>
                                </RestaurantProvider>
                            </DesktopProvider>
                        </Suspense>
                    </ThemeProvider>
                </LocaleProvider>
            </Sentry.ErrorBoundary>
        </I18nextProvider>
    )
}

export default ConfigProviders
