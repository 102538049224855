import React, { useContext, useEffect, useState } from 'react'
import { ninkasiFetcher } from '../api/fetchers'
import { useLocalStorage } from 'usehooks-ts'
import useRestaurantCodes from '../hooks/useRestaurantCodes'
import { MusicLabResponse } from '../features/MusicLab/MusicLab.type'
import TagManager from 'react-gtm-module'
import { Locale, LocaleContext } from './LocaleProvider'
import { differenceInHours } from 'date-fns'

export type RestaurantResponse = {
    menuUrl: string
    urlTable: string
    nomeSite: string
    agenda: string
    avisClient: string
    Facebook_URL: string
    Instagram_URL: string
    Paiement_URL: string
    CommandeBtn: boolean
    InscriptionBtn: boolean
    PaiementBtn: boolean
    AgendaBtn: boolean
    MenuBtn: boolean
    TripAdvisor_URL: string
    MusicLab?: MusicLabResponse
    Traduction: boolean
    NML_button?: boolean
}

export interface Restaurant {
    payOrderUrl?: string
    showMenuUrl?: string
    makeOrderUrl?: string
    name: string
    instagramUrl: string
    facebookUrl: string
    agendaUrl?: string
    googleReviewUrl?: string
    tripAdvisorUrl?: string
    musicLab?: MusicLabResponse
    translation: boolean
    nmlButton: boolean
}

export type CodeSiteType = string | number
export type CodeTableType = string | number

export interface RestaurantState {
    isLoading: boolean
    updatedAt?: Date
    codeSite?: CodeSiteType
    codeTable?: CodeTableType
    restaurant?: Restaurant
    initRestaurantState?: (codeSite: string, codeTable: string) => void
}

const defaultState: RestaurantState = {
    isLoading: false,
    updatedAt: undefined,
    codeSite: undefined,
    codeTable: undefined,
    restaurant: undefined,
}

export const RestaurantContext = React.createContext<RestaurantState>(defaultState)

const STORAGE_TTL_HOURS = 12
const RestaurantProvider = ({ children }: React.PropsWithChildren) => {
    const { locale } = useContext(LocaleContext)
    const { codeSite, codeTable } = useRestaurantCodes()
    const [restaurantState, setRestaurantState] = useLocalStorage('RestaurantState', defaultState)
    function isRestaurantStateAvailable(codeSite: string, codeTable: string) {
        const isRestaurantDiff = restaurantState?.codeSite !== codeSite || restaurantState?.codeTable !== codeTable
        const isStorageOutdated =
            !restaurantState?.updatedAt || // not cached
            (restaurantState?.updatedAt &&
                differenceInHours(new Date(), new Date(restaurantState.updatedAt)) >= STORAGE_TTL_HOURS) // ttl expired
        return !(codeSite && codeTable && (isRestaurantDiff || isStorageOutdated))
    }

    const [isValidating, setIsValidating] = useState(false)

    function initRestaurantState(codeSite: string, codeTable: string) {
        if (!isRestaurantStateAvailable(codeSite, codeTable)) {
            setIsValidating(true)
            ninkasiFetcher('/API_Data', { codeSite, codeTable, traduction_en: locale === Locale.en }).then(
                (data: RestaurantResponse) => {
                    setIsValidating(false)
                    if (data) {
                        setRestaurantState({
                            ...restaurantState,
                            updatedAt: new Date(),
                            codeSite: String(codeSite),
                            codeTable: String(codeTable),
                            restaurant: {
                                payOrderUrl: data.PaiementBtn ? data.Paiement_URL : undefined,
                                showMenuUrl: data.MenuBtn ? data.menuUrl : undefined,
                                makeOrderUrl: data.CommandeBtn ? data.urlTable : undefined,
                                name: data.nomeSite,
                                facebookUrl: data.Facebook_URL,
                                instagramUrl: data.Instagram_URL,
                                agendaUrl: data.AgendaBtn ? data.agenda : undefined,
                                googleReviewUrl: data.avisClient,
                                tripAdvisorUrl: data.TripAdvisor_URL,
                                musicLab: data?.MusicLab ?? undefined,
                                translation: data?.Traduction ?? false,
                                nmlButton: data?.NML_button ?? true,
                            },
                        })
                    }
                }
            )
        } else {
            console.info('No refresh required: restaurantState is valid')
        }
    }

    useEffect(() => {
        initRestaurantState(codeSite, codeTable)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeSite, codeTable])

    // set page title manually - https://github.com/nfl/react-helmet/issues/189
    document.title = ['Ninkasi', restaurantState.restaurant?.name].join(' ')

    // enable tag manager
    useEffect(() => {
        if (restaurantState.restaurant?.name && import.meta.env.VITE_GTM_ID) {
            TagManager.initialize({
                gtmId: import.meta.env.VITE_GTM_ID,
            })
        }
    }, [restaurantState.restaurant?.name])

    return (
        <RestaurantContext.Provider value={{ ...restaurantState, isLoading: isValidating, initRestaurantState }}>
            {children}
        </RestaurantContext.Provider>
    )
}

export default RestaurantProvider
