import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const MuiFormControlLabel = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiFormControlLabel']>{
        root: {
            alignItems: 'flex-start',
        },
    },
}

export default MuiFormControlLabel
