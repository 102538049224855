import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { fontFamilyDinNext } from '../typography'

const MuiChip = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiChip']>{
        root: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius,
            textTransform: 'uppercase',
            fontFamily: fontFamilyDinNext,
            height: 24,
        }),
        label: ({ theme }) => ({
            padding: theme.spacing(0, 1),
        }),
    },
}

export default MuiChip
