import React from 'react'

interface MercureState {
    hubUrl: string
    withCredentials?: boolean
    subscriberJwt?: string
    publisherJwt?: string
}

interface ProviderProps extends React.PropsWithChildren {
    options: MercureState
}

const defaultState = {
    hubUrl: '',
    withCredentials: false,
    subscriberJwt: undefined,
    publisherJwt: undefined,
}

export const MercureContext = React.createContext<MercureState>(defaultState)

const MercureProvider = ({ children, options }: ProviderProps) => {
    return <MercureContext.Provider value={{ ...defaultState, ...options }}>{children}</MercureContext.Provider>
}

export default MercureProvider
