import React from 'react'
import useLocalStorage from '../hooks/useLocalStorage'

type LocaleState = {
    locale: Locale
    onChangeLocale?: (locale: Locale) => void
}

export enum Locale {
    fr = 'fr',
    en = 'en',
}

export enum LocaleCulture {
    fr = 'fr-FR',
    en = 'en-GB',
}

const defaultState: LocaleState = {
    locale: Locale.fr,
}

export const LocaleContext = React.createContext<LocaleState>(defaultState)

const LocaleProvider = ({ children }: React.PropsWithChildren) => {
    const [locale, setLocale] = useLocalStorage('LocaleProvider', defaultState.locale)

    const onChangeLocale = (locale: Locale) => {
        setLocale(locale)
    }

    const provider = {
        locale: locale,
        onChangeLocale: onChangeLocale,
    }

    return <LocaleContext.Provider value={provider}>{children}</LocaleContext.Provider>
}

export default LocaleProvider
