import React from 'react'
import { Box, GlobalStyles, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

interface FullPageFeedbackProps {
    icon?: React.ReactNode
    surtitle?: string | React.ReactNode
    title?: string | React.ReactNode
    extra?: React.ReactNode
    sx?: object | undefined
}

const FullPageFeedback = ({ icon, surtitle, title, extra, sx = undefined }: FullPageFeedbackProps) => {
    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={0}
            sx={(theme) => ({
                color: theme.palette.common.white,
                textAlign: 'center',
                backgroundColor: theme.palette.common.black,
                height: 330,
                width: 320,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%)`,
                borderRadius: '1.5rem',
                ...(sx ? sx : {}),
            })}
        >
            <GlobalStyles
                styles={(theme) => ({
                    body: {
                        backgroundColor: theme.palette.common.black,
                    },
                })}
            />

            {icon}
            <Box>
                {Boolean(surtitle) && (
                    <Typography
                        whiteSpace="pre-line"
                        sx={(theme) => ({
                            color: theme.palette.common.white,
                            lineHeight: '2.3rem',
                            fontSize: '2rem',
                            py: '0.5rem',
                        })}
                    >
                        {surtitle}
                    </Typography>
                )}
                {Boolean(title) && (
                    <Typography
                        variant="h2"
                        whiteSpace="pre-line"
                        sx={(theme) => ({
                            color: theme.palette.common.white,
                            lineHeight: '4.5rem',
                            fontSize: '4.5rem',
                        })}
                    >
                        {title}
                    </Typography>
                )}
                {extra}
            </Box>
        </Stack>
    )
}

export default FullPageFeedback
