import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const MuiSlider = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiSlider']>{
        root: {
            marginBottom: 0,
            marginTop: 20,
            borderRadius: 0,
        },
        rail: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
        }),
        track: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            border: 'none',
        }),
        mark: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            width: 1,
            height: 12,
            borderRadius: 0,
            top: 'calc(50% - 4px)',
            opacity: 1,
            '&.MuiSlider-markActive': {
                backgroundColor: theme.palette.secondary.main,
            },
        }),
        markActive: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
        }),
        markLabel: {
            top: '-20px !important',
            height: 30,
            display: 'flex',
            alignItems: 'flex-end',
        },
        thumb: ({ theme, ownerState }) => ({
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.secondary.main,
            borderWidth: 3,
            borderStyle: 'solid',
            ...(ownerState.value === -1 && {
                borderColor: theme.palette.primary.light,
            }),
        }),
    },
}

export default MuiSlider
