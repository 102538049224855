import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { fontFamilyRoboto, typographyOverline } from '../typography'

const MuiInputLabel = {
    defaultProps: {
        shrink: true,
    },
    styleOverrides: <ComponentsOverrides<Theme>['MuiInputLabel']>{
        root: ({ theme }) => ({
            ...typographyOverline,
            transform: 'none',
            textTransform: 'none',
            color: theme.palette.common.black,
            fontSize: '2rem',
            fontFamily: fontFamilyRoboto,
            fontWeight: 500,
            lineHeight: '2rem',
        }),
        asterisk: ({ theme }) => ({
            color: theme.palette.secondary.main,
        }),
    },
}

export default MuiInputLabel
