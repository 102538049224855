import React, { useEffect } from 'react'
import { Button, Snackbar } from '@mui/material'
import { useRegisterSW } from 'virtual:pwa-register/react'
import useNinkaTvDetector from '../../hooks/useNinkaTvDetector'

const refreshIntervalMs = 60 * 60 * 1000 // 1h

const ReloadPrompt = () => {
    const {
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegistered(r) {
            // eslint-disable-next-line prefer-template
            setInterval(() => {
                r?.update?.()
            }, refreshIntervalMs)
        },
        onRegisterError(error) {
            console.error('SW registration error', error)
        },
    })
    const { isNinkaTv } = useNinkaTvDetector()

    // auto update Ninka Tv service worker
    useEffect(() => {
        if (isNinkaTv && needRefresh) {
            updateServiceWorker(true)
        }
    }, [isNinkaTv, needRefresh, updateServiceWorker])

    const handleClose = () => {
        setNeedRefresh(false)
    }

    return (
        <Snackbar
            open={needRefresh}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            message="Une mise à jour de l’application est disponible"
            action={
                <Button color="secondary" size="small" onClick={() => updateServiceWorker(true)}>
                    Recharger
                </Button>
            }
        />
    )
}

export default ReloadPrompt
