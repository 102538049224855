import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const MuiButton = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiButton']>{
        root: ({ ownerState, theme }) => ({
            borderRadius: 999,
            padding: theme.spacing(1),
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            ...(ownerState.variant === 'text' &&
                ownerState.color === 'primary' && {
                    fontSize: '1.8rem',
                    borderRadius: 0,
                    color: theme.palette.primary.main,
                    borderBottom: `solid 2px ${theme.palette.common.black}`,
                    padding: theme.spacing(1, 0, 0, 0),
                    margin: theme.spacing(0, 1, 1, 1),
                }),
            ...(ownerState.variant === 'contained' &&
                ownerState.color === 'primary' && {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                }),
            ...(ownerState.size === 'large' && {
                padding: theme.spacing(2),
                fontSize: '2rem',
            }),
            ...((ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
                ...(Boolean(ownerState.endIcon) && {
                    paddingLeft: theme.spacing(2),
                    ...(ownerState.size === 'large' && {
                        paddingLeft: theme.spacing(4),
                    }),
                }),
            }),
            '&.Mui-disabled': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
            },
        }),
        endIcon: ({ theme }) => ({
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            transition: theme.transitions.create(['background-color', 'color'], {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut,
                delay: 0,
            }),
            borderRadius: '50%',
            display: 'flex',
            marginRight: 0,
            alignItems: 'center',
            justifyContent: 'center',
            width: 48,
            height: 48,
            '&>svg': {
                width: 'fit-content',
                maxWidth: 20,
                maxHeight: 20,
            },
        }),
    },
}

export default MuiButton
