import { createTheme } from '@mui/material'
import palette from './palette'
import typography from './typography'
import MuiButton from './components/MuiButton'
import MuiTypography from './components/MuiTypography'
import MuiBottomNavigation from './components/MuiBottomNavigation'
import MuiBottomNavigationAction from './components/MuiBottomNavigationAction'
import MuiCssBaseline from './components/MuiCssBaseline'
import MuiChip from './components/MuiChip'
import MuiSlider from './components/MuiSlider'
import MuiInputLabel from './components/MuiInputLabel'
import MuiFormControl from './components/MuiFormControl'
import MuiOutlinedInput from './components/MuiOutlinedInput'
import MuiFormControlLabel from './components/MuiFormControlLabel'
import MuiSnackbarContent from './components/MuiSnackbarContent'

export const theme = createTheme({
    palette,
    typography,
    components: {
        MuiCssBaseline,
        MuiButton,
        MuiTypography,
        MuiBottomNavigation,
        MuiBottomNavigationAction,
        MuiFormControl,
        MuiFormControlLabel,
        MuiInputLabel,
        MuiOutlinedInput,
        MuiChip,
        MuiSlider,
        MuiSnackbarContent,
    },
})
