import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const MuiBottomNavigationHeight = 56

const MuiBottomNavigation = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiBottomNavigation']>{
        root: {
            height: MuiBottomNavigationHeight,
        },
    },
}

export default MuiBottomNavigation
