import React from 'react'
import FullPageFeedback from './FullPageFeedback'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { GlobalStyles } from '@mui/material'
import { GLOBAL_MAX_WIDTH_STYLE } from '../../providers/ConfigProviders'

const RestaurantNotFound = () => {
    return (
        <>
            <GlobalStyles styles={GLOBAL_MAX_WIDTH_STYLE} />
            <FullPageFeedback
                icon={<QrCode2Icon sx={{ width: 120, height: 120 }} />}
                title="Pour pouvoir utiliser l’application, scannez le QR Code de l’établissement."
                sx={{
                    marginLeft: '50%',
                    minHeight: '100vh',
                    transform: 'translate(-50%, 0%)',
                    maxWidth: '500px',
                    position: 'static',
                }}
            />
        </>
    )
}

export default RestaurantNotFound
