import { SimplePaletteColorOptions } from '@mui/material'
import { PaletteOptions } from '@mui/material/styles/createPalette'

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        accent: SimplePaletteColorOptions
        crashTaste: SimplePaletteColorOptions
    }

    interface PaletteOptions {
        accent: SimplePaletteColorOptions
        crashTaste: SimplePaletteColorOptions
    }
}

const palette: PaletteOptions = {
    common: {
        black: '#000000',
    },
    primary: {
        light: '#b7b7b7',
        main: '#161615',
        dark: '#161615',
        contrastText: '#fff',
    },
    secondary: {
        light: '#ecb0b0',
        main: '#ff3333',
        dark: '#b22323',
        contrastText: '#fff',
    },
    accent: {
        light: '#fcf411',
        main: '#f0e803',
        dark: '#d8d002',
        contrastText: '#161615',
    },
    crashTaste: {
        main: '#fce880',
        contrastText: '#161615',
    },
    error: {
        main: '#ff3333',
        contrastText: '#fff',
    },
}

export default palette
