import { useMediaQuery, useTheme } from '@mui/material'

const useIsDesktop = () => {
    const { breakpoints } = useTheme()
    const isDesktop = useMediaQuery(`(min-width:${breakpoints.values.md}px)`)

    return { isDesktop }
}

export default useIsDesktop
