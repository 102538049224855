import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const MuiFormControl = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiFormControl']>{
        root: ({ theme }) => ({
            paddingTop: theme.spacing(4),
        }),
    },
}

export default MuiFormControl
