import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Palette } from '@mui/material/styles/createPalette'
import { fontDinNext, fontRoboto } from './components/MuiCssBaseline'

export const fontFamilyRoboto = `'${fontRoboto}', sans-serif`
export const fontFamilyDinNext = `'${fontDinNext}', sans-serif`

export const typographyTitle = {
    fontFamily: fontFamilyDinNext,
    fontWeight: 500,
    textTransform: 'uppercase' as const,
}
export const typographyOverline = {
    ...typographyTitle,
    fontWeight: 300,
    fontSize: '1.8rem',
    lineHeight: 1.6,
}

const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily: fontFamilyRoboto,
    body2: {
        fontFamily: fontFamilyDinNext,
        fontSize: '1.6rem',
    },
    h1: {
        ...typographyTitle,
        fontSize: '4.9rem',
    },
    h2: {
        ...typographyTitle,
        fontSize: '3.6rem',
    },
    h3: {
        ...typographyTitle,
        fontSize: '3.0rem',
    },
    h4: {
        ...typographyTitle,
        fontSize: '2.4rem',
    },
    h5: {
        ...typographyTitle,
        fontWeight: 'normal',
        fontSize: '2.4rem',
    },
    h6: {
        ...typographyTitle,
        fontSize: '2.0rem',
        lineHeight: 1.2,
    },
    button: {
        ...typographyTitle,
        fontSize: '2.2rem',
    },
    caption: {
        fontSize: '1.4rem',
    },
    overline: typographyOverline,
}

export default typography
