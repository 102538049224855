export enum MenuDigitalVariant {
    Food = 'Food',
    FoodWeb = 'Food-web',
    FoodDev = 'Food2',
    FoodWebDev = 'Food-web2',
    Drink = 'Boissons',
    DrinkWeb = 'Boissons-web',
    DrinkDev = 'Boissons2',
    DrinkWebDev = 'Boissons-web2',
    Print = 'Print',
    PrintDev = 'Print2',
}

// type guards
export const isMenuDigitalVariant = (variant: string | null): boolean => {
    return !!variant && Object.values(MenuDigitalVariant).includes(variant as MenuDigitalVariant)
}

export const getMenuDigitalVariantKeyByValue = (value: string) => {
    if (!isMenuDigitalVariant(value)) {
        return undefined
    }
    return Object.keys(MenuDigitalVariant)[Object.values(MenuDigitalVariant).indexOf(value as MenuDigitalVariant)]
}

export enum TypeContent {
    Article = 'Article',
    ZoneText = 'ZoneText',
    Lien = 'Lien',
    Texte = 'Texte',
    ImageCentered = 'ImageCentered',
    ImageFullWidth = 'ImageFullWidth',
    Bouton = 'Bouton',
    Liste = 'Liste',
    Ancre = 'Ancre',
}

export enum TypeArticle {
    Formule = 'Formule',
    Produit = 'Produit',
    Supplement = 'Supplement',
}

// TODO: Avec le mode print status actif & inactif
export enum ItemStatus {
    Actif = 'Actif', //  Toujours visible
    Inactif = 'Inactif', // Toujours Masqué
    // Rupture = 'Rupture', // Print uniquement
    ActifDigital = 'Actif Digital', // Screen uniquement,
    Rupture = 'Actif Print', // Print uniquement
}

export const isValidItemStatus = (status: string): boolean => {
    return Object.values(ItemStatus).includes(status as ItemStatus)
}

export type MenuDigitalResponse = {
    Libelle: string
    AnneeCarte: string
    ImageCarte: string
    Type: string
    DateDebut: string
    DateFin: string
    CodeSite: number
    Section: SectionResponse[]
}

interface BaseSectionContent {
    TypeContent: TypeContent
    Libelle: string
    Ordre: number
    Content_Description: string
    Prix?: number
    Statut: ItemStatus
}

export interface ZoneTextContentResponse extends BaseSectionContent {
    Contenu_Description: string
    CouleurPolice?: string
}

export interface ListContentResponse extends BaseSectionContent {
    Contenu_Description: string
    Liste: string[]
}

export interface ImageContentResponse extends ZoneTextContentResponse {
    Contenu_Image: string
}

export interface LinkContentResponse extends BaseSectionContent {
    Contenu_Description: string
    URL_lien: string
}

export interface BoutonContentResponse extends BaseSectionContent {
    Contenu_Description: string
    URL_lien: string
    CouleurPolice?: string
    Fond?: string
}

export interface AncreContentResponse extends BaseSectionContent {
    Contenu_Description: string
    URL_lien: string
}

export type ContentResponse =
    | ZoneTextContentResponse
    | LinkContentResponse
    | BoutonContentResponse
    | AncreContentResponse
    | ImageContentResponse

export enum ModeAffichagePrint {
    Body2Col = 'Body 2 Col',
    Header = 'Header',
    Footer = 'Footer',
}
export type SectionResponse = {
    Libelle: string
    Description: string
    Fond: string
    Categories: CategoryResponse[]
    Contenu_Description: string
    Ancre?: string
    ModeAffichage_Print?: ModeAffichagePrint
    Accentuation_Libelle_Print?: string
    SousTitre_Print?: string
    Taille_Libelle_Print?: string
    Taille_Titre_Print?: string
    Titre_Print?: string
    Image_Titre_Print?: string
}

export type CategoryResponse = {
    Libelle: string
    CouleurPolice: string
    Fond: string
    Articles: ArticleResponse[]
    Contenu: ContentResponse[]
    Ancre?: string
    URL_image?: string
    Saut_Page?: 1
    Saut_Espace?: string
}

export type ArticleResponse = {
    Libelle: string
    Picto?: string | undefined
    Predescription?: string | undefined
    TypeContent: TypeContent
    TypeArticle: TypeArticle
    Type_Biere?: 'Bouteille' | 'Pression'
    Taux_alcool?: string | number
    Description_1?: string
    Description_2?: string
    Description_3?: string
    Logo1?: string[]
    Logo2?: string[]
    Ordre: number
    Format: ArticleFormatResponse[]
    URL_image_fr?: string
    BarreAlcool?: number
    Palais?: string
    Nez?: string
    Bouche?: string
    Finale?: string
}

export type FormuleResponse = {
    Libelle: string
    URL_image_fr: string
    Ordre: number
    TypeArticle: TypeArticle
    Format: ArticleFormatResponse[]
    TypeContent: TypeContent
}

export type ArticleFormatResponse = {
    Libelle: string
    Picto?: string
    Prix: ArticlePriceResponse[]
}

export type ArticlePriceResponse = {
    Libelle: string
    Ordre: number
    Tarif: ArticlePriceType | string
    Prix: number
    Statut: ItemStatus
}

export enum ArticlePriceType {
    OnSite = 'Sur Place',
    HappyHour = 'Happy Hour',
}
