import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import detector from 'i18next-browser-languagedetector'
import { Locale } from '../providers/LocaleProvider'

i18n.use(detector)
    // https://www.i18next.com/how-to/add-or-load-translations#lazy-load-in-memory-translations
    .use(
        resourcesToBackend((language, namespace, callback) => {
            import(`./${language}/${namespace}.json`)
                .then((resources) => {
                    callback(null, resources)
                })
                .catch((error) => {
                    console.error(error)
                    callback(error, null)
                })
        })
    )
    // pass the i18n instance to react-i18next.
    .use(intervalPlural)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        ns: ['common'],
        fallbackLng: Locale.fr,
        debug: import.meta.env.MODE === 'development',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
