import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { fontFamilyDinNext } from '../typography'

const MuiBottomNavigationAction = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiBottomNavigationAction']>{
        root: ({ theme }) => ({
            color: theme.palette.common.black,
            padding: theme.spacing(0.5, 1.5),
        }),
        label: {
            fontFamily: fontFamilyDinNext,
        },
    },
}

export default MuiBottomNavigationAction
