import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ConfigProviders from './providers/ConfigProviders'
import ReloadPrompt from './components/Feedback/ReloadPrompt'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

console.log('init', import.meta.env.VITE_SENTRY_DSN)
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: [import.meta.env.VITE_APP_ENV, 'front'].join('-'),
    release: import.meta.env.SENTRY_RELEASE?.id,
    integrations: [new BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tracesSampleRate: 0,
    beforeSend: (event, hint) => {
        if (hint.originalException instanceof TypeError) {
            const { stack } = hint.originalException
            if (stack?.includes('https://www.googletagmanager.com/gtm.js')) {
                return null
            }
        }
        return event
    },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ConfigProviders>
        <App />
        <ReloadPrompt />
    </ConfigProviders>
)
