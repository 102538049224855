import { Theme } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const MuiSnackbarContent = {
    styleOverrides: <ComponentsOverrides<Theme>['MuiSnackbarContent']>{
        root: ({ theme }) => ({
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        }),
        message: {
            fontSize: '1.6rem',
        },
    },
}

export default MuiSnackbarContent
