import React from 'react'
import { GlobalStyles, Stack } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { ReactComponent as Brand } from '../../assets/images/ninkasi.svg'
import { ReactComponent as Loading } from '../../assets/images/loading.svg'
import merge from 'lodash/merge'
import { GLOBAL_MAX_WIDTH_STYLE } from '../../providers/ConfigProviders'

const LoadingPage = () => {
    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    ...merge(GLOBAL_MAX_WIDTH_STYLE, {
                        body: {
                            backgroundColor: theme.palette.common.black,
                        },
                    }),
                })}
            />
            <Stack
                sx={(theme) => ({
                    color: theme.palette.common.white,
                    height: '100vh',
                })}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
            >
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Logo height={50} />
                    <Brand height={22} />
                </Stack>

                <Loading height={32} />
            </Stack>
        </>
    )
}

export default LoadingPage
